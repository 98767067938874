import React from "react"
import { Wrapper, StyledImage } from "./style"
import { Link } from "gatsby"

import { H3, Description } from "../elements"
import { Col, Row } from "react-bootstrap"

import { Container } from "../elements"

export const Services = ({
  images,
  services,
  title,
  description,
  cover,
  odd,
}) => {
  return (
    <Wrapper Tag="section" fluid={cover.fluid}>
      <div
        className={`${odd ? "overlay" : "overlay-reverse"}`}
        id={`${title.toLowerCase()}`}
      >
        <Container>
          <Row className="m-0">
            <Col className="title" lg={3}>
              <H3 className="text-capitalize">{title}</H3>
              {description.childContentfulRichText.html && (
                <Description
                  dangerouslySetInnerHTML={{
                    __html: description.childContentfulRichText.html,
                  }}
                />
              )}
            </Col>
            <Col lg={9}>
              <Row className="m-0">
                {services.map((node, k) => {
                  return (
                    <Col key={node.id + k} className="item" lg={4}>
                      <Link to={`/${node.slug}`}>
                        <span className="number">{`0${k + 1}.`}</span>
                        <h3 className="text-capitalize">{node.title}</h3>
                        <p>{node.summary}</p>
                        <Row className="m-0 align-items-center">
                          <small className="m-0 mr-3">En savoir plus</small>
                          <StyledImage
                            alt={`voir plus ${node.title}`}
                            fixed={images.childImageSharp.fixed}
                          />
                        </Row>
                      </Link>
                    </Col>
                  )
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Wrapper>
  )
}

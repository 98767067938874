import React from "react"
import { Wrapper } from "./style"
import { Row, Col } from "react-bootstrap"
import { Container, H3 } from "../elements"

export const Looking = () => {
  return (
    <Wrapper id="jobs">
      <Container>
        <div className="d-block d-lg-none blue">
          <H3>Nous recherchons des</H3>
        </div>
        <Row className="m-0">
          <Col lg={4} className="item pr">
            <div className="d-none d-lg-block">
              <H3>Nous recherchons des</H3>
            </div>
            <Row className="m-0 justify-content-lg-between align-items-center align-items-lg-stretch">
              <small className="mr-5 mr-lg-0">2020 -</small>
              <div>
                <h2>Soudeurs Tig</h2>
                <p>Reims, FR</p>
              </div>
            </Row>
          </Col>
          <Col lg={4} className="item middleBorder pr pl">
            <Row className="m-0 justify-content-lg-between align-items-center align-items-lg-stretch">
              <small className="mr-5 mr-lg-0">2020 -</small>
              <div>
                <h2>Soudeurs Tig</h2>
                <p>Luxembourg, LU</p>
              </div>
            </Row>
          </Col>
          <Col lg={4} className="item pl">
            <Row className="m-0 justify-content-lg-between align-items-center align-items-lg-stretch">
              <small className="mr-5 mr-lg-0">2020 -</small>
              <div>
                <h2>Soudeurs Mig</h2>
                <p>Charleville-Mezière, FR</p>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

import styled from "styled-components"

export const Wrapper = styled.section`
  background-color: ${props => props.theme.colors.secondaire};
  color: white;
  padding-bottom: 150px;
  h3 {
    color: white;
  }
  .blue h3 {
    color: ${props => props.theme.colors.text};
  }
  .item {
    position: relative;
    padding: 0;
    padding-top: 150px;
    h3 {
      position: absolute;
      top: 50%;
      transform: translateY(-70%);
    }
    h2 {
      font-size: 3rem;
      color: ${props => props.theme.colors.primary};
      font-weight: 700;
    }
    small {
      color: ${props => props.theme.colors.lightText};
      font-size: 1.5rem;
      font-family: "PT Mono", monospace;
    }
    p {
      color: ${props => props.theme.colors.lightText};
      font-size: 1.9rem;
    }
  }
  .pl {
    padding-left: 60px;
  }
  .pr {
    padding-right: 60px;
  }
  .middleBorder {
    border-left: 1px rgba(239, 239, 239, 0.17) solid;
    border-right: 1px rgba(239, 239, 239, 0.17) solid;
  }
  @media screen and (max-width: 576px) {
    background-color: white;
    color: black;
    padding-bottom: 50px;
    padding-top: 50px;
    .middleBorder {
      border-left: none;
      border-right: none;
    }
    .pl {
      padding-left: 0px;
    }
    .pr {
      padding-right: 0px;
    }
    .item {
      padding-top: 20px;
      h2 {
        font-size: 2rem;
      }
      small {
        font-size: 1.5rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
`

import styled from "styled-components"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export const StyledImage = styled(Img)`
  filter: grayscale();
  opacity: 0.5;
  max-width: 70%;
  height: auto;
`

export const Wrapper = styled(BackgroundImage)`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  a,
  a:hover {
    text-decoration: none;
  }
  .overlay {
    padding-bottom: 75px;
    background-color: rgba(36, 73, 133, 0.95);
    width: 100%;
    height: 100%;
    .item {
      border-left: 1px rgba(239, 239, 239, 0.17) solid;
    }
    p {
      color: ${props => props.theme.colors.lightText};
    }
    a {
      color: ${props => props.theme.colors.lightText};
    }
    h3 {
      color: ${props => props.theme.colors.white};
    }
  }
  .overlay-reverse {
    padding-bottom: 75px;
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    height: 100%;
    .item {
      border-left: 1px ${props => props.theme.colors.secondaire} solid;
    }

    h3 {
      color: ${props => props.theme.colors.secondaire};
    }
    a {
      color: ${props => props.theme.colors.lightText};
    }
  }

  .title {
    padding-top: 75px;
    color: ${props => props.theme.colors.lightText};
    p {
      text-align: left;
      font-size: 1.6rem;
    }
  }
  .item {
    padding: 100px 20px 0 20px;
    .number {
      color: ${props => props.theme.colors.primary};
      font-size: 1.4rem;
      font-family: "PT Mono", monospace;
    }
    h3 {
      font-size: 2.2rem;
      margin-bottom: 15px;
      margin-top: 30px;
    }
    p {
      font-size: 1.6rem;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 576px) {
      margin-top: 50px;
      padding: 0px 20px 0 20px;
      h3 {
        margin-top: 20px;
      }
    }
    :hover .gatsby-image-wrapper {
      filter: none;
      opacity: 1;
      transition-duration: 500ms;
    }
  }
  .arrows {
    text-align: right;
    margin-top: 50px;
    .btn {
      color: ${props => props.theme.colors.lightText};
      font-size: 1.3rem;
      opacity: 0.7;
      &:hover {
        color: white;
      }
    }
  }
`

import styled from "styled-components"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"

export const Wrapper = styled(Row)`
  margin: 0;
`

export const Item = styled(Col)`
  height: 320px;
  border: 1px #efefef solid;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:nth-child(even) {
    margin-top: 100px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
  &:hover .gatsby-image-wrapper {
    opacity: 1;
  }
`

export const StyledImage = styled(Img)`
  filter: grayscale();
  opacity: 0.5;
  max-width: 70%;
  height: auto;
`

import React from "react"
import { Wrapper, Cover } from "./style"
import { H3, Container } from "../../elements"

export const ServicesGallery = ({ services }) => {
  return (
    <Wrapper>
      <Container>
        <H3 className="text-capitalize mb-5">Nos réalisations</H3>
        <div className="image-grid">
          {services.map(s => {
            return (
              <div key={s.id} className="image-item">
                <Cover
                  Tag={"section"}
                  backgroundColor={`#040e18`}
                  fluid={s.cover.fluid}
                  className="cover"
                ></Cover>
                <div className="overlay">
                  <H3 className="text-capitalize">{s.title}</H3>
                  <p className="summary">{s.description.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Wrapper>
  )
}

import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

export const Wrapper = styled.section`
  padding: 75px 0;
  .image-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: minmax(500px, auto);
  }
  .image-item {
    overflow: hidden;
    position: relative;
    .overlay {
      position: absolute;
      padding: 30px;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0);
      transition-duration: 500ms;
    }
    h3,
    p {
      color: white;
      opacity: 0;
      transition-duration: 500ms;
    }
    :hover h3 {
      opacity: 1;
    }
    :hover p {
      opacity: 1;
    }
    :hover .overlay {
      background-color: rgba(0, 0, 0, 0.6);
    }
    :hover .cover {
      transform: scale(1.1);
    }
  }
  .image-grid .image-item:nth-child(5n) {
    grid-column-end: span 2;
  }
`

export const Cover = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition-duration: 500ms;
`

import React from "react"
import { Col, Row, Nav, Tab } from "react-bootstrap"
import { MainLayout, Section } from "../components/containers"
import SEO from "../components/SEO"
import { MainButton, BgImage } from "../components/ui"
import { H1, Container, H3, H2 } from "../components/elements"
import { ServicesGallery } from "../components/galleries"
import { Services } from "../components/services"
import { Looking } from "../components/looking"
import { ContactForm } from "../components/forms"
import Styled from "styled-components"

const Headband = Styled.section`
  h1 {
    color: white;
    margin-bottom: 0;
  }
  p{
    color: white;
  }
  .contactbtn{
    margin-top: 50px
  }
`

const TabsWrapper = Styled.section`
  h3{
    margin-bottom: 50px;
  }
  .nav-pills .nav-link:focus{
    outline: none;
  }
  .nav-pills .nav-link{
    border: 2px solid ${props => props.theme.colors.secondaire};
    border-radius: 0;
    background-color: white;
    margin: 5px 0;
    font-weight: 500;
    color: ${props => props.theme.colors.text};
  }
  .nav-pills .nav-link.active{
    background-color: ${props => props.theme.colors.secondaire};
    color: white;
  }
  @media screen and (max-width: 576px) {
    h3{
      margin-bottom: 25px;
    }
  }
`

const IndexPage = ({ data }) => {
  const images = data.arrow
  const references = data.references.nodes
  const categories = data.allContentfulCategories.nodes
  const infos = data.infos.edges
  const title =
    data.title.childContentfulSettingValueRichTextNode.childContentfulRichText
      .html
  const subtitle =
    data.title.childContentfulSettingContentRichTextNode.childContentfulRichText
      .html
  const about_intro =
    data.about_intro.childContentfulSettingValueRichTextNode
      .childContentfulRichText.html
  return (
    <MainLayout>
      <SEO title="Accueil" />
      <Headband>
        <BgImage>
          <Section>
            <Container fluid>
              <Col className="p-0" lg={8}>
                <H1 dangerouslySetInnerHTML={{ __html: title }} />
                <H2 dangerouslySetInnerHTML={{ __html: subtitle }} />
              </Col>
              <div className="mb-5 contactbtn mb-lg-0">
                <MainButton to="/#contact">Contact</MainButton>
              </div>
            </Container>
          </Section>
        </BgImage>
      </Headband>
      <Section>
        <TabsWrapper>
          <Container>
            <H3 dangerouslySetInnerHTML={{ __html: about_intro }} />
            <Tab.Container
              defaultActiveKey={infos[0].node.slug}
              id="left-tabs-example"
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {infos.map(info => {
                      return (
                        <Nav.Item key={info.node.id}>
                          <Nav.Link eventKey={info.node.slug}>
                            {info.node.name}
                          </Nav.Link>
                        </Nav.Item>
                      )
                    })}
                  </Nav>
                </Col>
                <Col sm={9} className="mt-5 mt-lg-0">
                  <Tab.Content>
                    {infos.map(info => {
                      return (
                        <Tab.Pane key={info.node.id} eventKey={info.node.slug}>
                          <div
                            className="text-blue"
                            dangerouslySetInnerHTML={{
                              __html:
                                info.node.content.childContentfulRichText.html,
                            }}
                          />
                        </Tab.Pane>
                      )
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </TabsWrapper>
      </Section>

      {categories.map((c, k) => (
        <Services
          key={c.id}
          odd={k % 2}
          services={c.services}
          title={c.name}
          description={c.childContentfulCategoriesContentRichTextNode}
          images={images}
          cover={c.cover}
        />
      ))}

      <div className="d-none d-lg-block" id="realisations">
        <ServicesGallery services={references} />
      </div>
      <Looking />
      <div className="d-block d-lg-none">
        <hr />
      </div>
      <ContactForm />
    </MainLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    arrow: file(relativePath: { eq: "arrow.png" }) {
      childImageSharp {
        fixed(width: 30, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    about_intro: contentfulSetting(name: { eq: "about-intro" }) {
      name
      childContentfulSettingValueRichTextNode {
        childContentfulRichText {
          html
        }
      }
    }
    title: contentfulSetting(name: { eq: "title" }) {
      name
      childContentfulSettingValueRichTextNode {
        childContentfulRichText {
          html
        }
      }
      childContentfulSettingContentRichTextNode {
        childContentfulRichText {
          html
        }
      }
    }
    references: allContentfulReferences {
      nodes {
        title
        id
        description {
          description
        }
        cover {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    infos: allContentfulInformations(sort: { order: ASC, fields: position }) {
      edges {
        node {
          content {
            childContentfulRichText {
              html
            }
          }
          name
          slug
          id
        }
      }
    }
    allContentfulService(sort: { fields: [updatedAt], order: ASC }) {
      totalCount
      edges {
        node {
          title
          slug
          summary
        }
      }
    }
    allContentfulCategories(sort: { fields: [position], order: ASC }) {
      nodes {
        id
        childContentfulCategoriesContentRichTextNode {
          childContentfulRichText {
            html
          }
        }
        cover {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyContentfulFluid
          }
        }
        name
        services {
          ... on ContentfulService {
            id
            slug
            summary
            title
          }
        }
      }
    }
  }
`
